<template>
    <div>
        <div style="width: 240px; overflow: hidden">
            <strong class="text-primary">{{ charger.external_id }}</strong>
        </div>
        <br>
        Address:
        <br>
        <strong>{{ charger.address }}</strong>
        <br>
        Connector:
        <br>
        <strong>{{ charger.port }} | {{ charger.pow }} kW</strong>
        <br>
        <strong><span class="text-secondary">Occupation Data:</span></strong>
        <div class="list-group-item" style="width: 240px; overflow: scroll">
            <occupation-chart :occupation="charger.occupancy"></occupation-chart>
        </div>
        <br>
        <a class="btn btn-secondary btn-sm" @click="useThis">Use This Station</a>
        <br>
    </div>
</template>
<script>
import OccupationChart from "@/components/OccupationChart";
export default {
    name: "MarkerPopUp",
    components: {
        OccupationChart
    },
    data() {
        return {
            charger: {}
        }
    },
    props: {
        charger: {
            type: Object,
            default: {},
            required: true
        },
        useButtonWasPressed: {
            type: Function,
            default: () => true
        }
    },
    computed: {},
    watch: {},
    methods: {
        useThis() {
            this.useButtonWasPressed()
        }
    }
}
</script>
<style scoped>
</style>