<template>
    <div>
        <b-overlay :show="show" rounded="sm">
            <div :aria-hidden="show ? 'true' : null">
                <stack-header :show-back-arrow="false" :title="$t('planner.title')" />
                <b-card-body>
                    <form class="bottom-padding">
                        <!-- start point -->
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-primary">
                                    <font-awesome-icon class="text-white" icon="map-pin" />
                                </span>
                            </div>
                            <div id="source" class="form-control"></div>
                            <pre id="source_result"></pre>
                            <div class="input-group-append">
                                <button type="button" v-bind:class="{
                                    'btn btn-secondary text-white': isTracking,
                                    'btn btn-primary': !isTracking,
                                }" v-on:click="localiseMe">
                                    <font-awesome-icon icon="crosshairs"
                                        v-bind:class="{ 'fa-spin': isTracking, '': !isTracking }" />
                                </button>
                            </div>
                        </div>
                        <!-- waypoints -->
                        <div v-for="(waypoint, index) in waypoints" :key="index" class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-light">
                                    <font-awesome-icon icon="map-signs" />
                                </span>
                            </div>
                            <div :id="'wp_' + index" class="form-control waypoint"></div>
                            <pre :id="'wp_' + index + '_result'"></pre>
                            <div class="input-group-append">
                                <button class="btn btn-danger" type="button" v-on:click="removeWaypoint(index)">
                                    <font-awesome-icon icon="times" />
                                </button>
                            </div>
                            <div class="text-center d-flex clearfix w-100 m-2 justify-content-center">
                                <b-form-radio-group :id="'rd_' + index" v-model="waypoint.selected"
                                    :options="waypoint.options" button-variant="outline-secondary" buttons
                                    class="d-flex flex-wrap" name="radio-btn-outline" size="sm"></b-form-radio-group>
                            </div>
                        </div>
                        <!--  button add -->
                        <div class="input-group mb-3 d-flex justify-content-center">
                            <div class="dotted">
                                <b-button size="sm" variant="outline-primary" @click.prevent="addWaypoint">+ Add
                                    Waypoints
                                </b-button>
                            </div>
                        </div>
                        <!-- end point -->
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-secondary">
                                    <font-awesome-icon class="text-white" icon="flag" />
                                </span>
                            </div>
                            <div id="destination" class="form-control"></div>
                            <pre id="destination_result"></pre>
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" v-on:click="invert()">
                                    <font-awesome-icon icon="random" />
                                </button>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <font-awesome-icon icon="car-alt" />
                                </span>
                            </div>
                            <select id="car" v-model="form.car" class="form-control" @change="updateCar" required>
                                <option hidden value="">{{ $t("planner.form.carModelPlaceholder") }}</option>
                                <option v-for="option in evData.data" :key="option.id" :value="option.id">
                                    {{ option.brand }} - {{ option.model }} - {{ option.variant }} - {{
                                            option.release_year
                                    }}
                                </option>
                            </select>
                            <div
                                v-bind:class="{ 'invalid-feedback': !isNotSelected, 'invalid-feedback d-block': isNotSelected }">
                                Please choose a car.
                            </div>
                        </div>
                        <!-- start battery -->
                        <div class="form-group mb-3 bg-light p-2 radius">
                            <b-row>
                                <b-col cols="8">
                                    <label class="text-primary" for="battery">
                                        <font-awesome-icon class="text-primary" icon="battery-three-quarters" />
                                        {{ $t("planner.form.battery") }}
                                    </label>
                                </b-col>
                                <b-col class="text-right" cols="4">
                                    <small class="bg-primary text-white text-center rounded p-1">{{ form.battery
                                    }}%</small>
                                </b-col>
                            </b-row>
                            <input id="battery" v-model="form.battery" :style="startBatteryCss"
                                class="form-control-range" max="100" min="0" name="battery" type="range"
                                @input="updateBattery" />
                            <p class="text-black-50 small">{{ $t("planner.form.batteryDesc") }}</p>
                        </div>
                        <b-button :aria-expanded="advanceOptionsAreVisible ? 'true' : 'false'"
                            :class="advanceOptionsAreVisible ? null : 'collapsed'" aria-controls="collapse-4" block
                            size="sm" variant="outline-primary"
                            @click="advanceOptionsAreVisible = !advanceOptionsAreVisible">
                            {{ advanceOptionsAreVisible ? "- Close Options" : "+ Show Advanded Options" }}
                        </b-button>
                        <b-collapse v-model="advanceOptionsAreVisible" class="mt-2">
                            <!-- end battery -->
                            <div class="form-group mb-3 bg-light p-2 radius">
                                <b-row>
                                    <b-col cols="8">
                                        <label class="text-primary" for="end-battery">
                                            <font-awesome-icon class="text-primary" icon="battery-three-quarters" />
                                            {{ $t("planner.form.endBattery") }}
                                        </label>
                                    </b-col>
                                    <b-col class="text-right" cols="4">
                                        <small class="bg-primary text-white text-center rounded p-1">{{
                                                options.endBattery
                                        }}%</small>
                                    </b-col>
                                </b-row>
                                <input id="end-battery" v-model="options.endBattery" :style="endBatteryCss"
                                    class="form-control-range" max="100" min="0" name="end-battery" type="range"
                                    @input="updateEndBattery" />
                                <p class="text-black-50 small">{{ $t("planner.form.endBatteryDesc") }}</p>
                            </div>
                            <div class="form-group mb-3 bg-light p-2 radius bottom-margin">
                                <b-row>
                                    <b-col>
                                        <label class="text-primary" for="end-battery">
                                            <font-awesome-icon class="text-primary" icon="clock" />
                                            Start Time
                                        </label>
                                    </b-col>
                                </b-row>
                                <div class="text-center">
                                    <b-time id="time" v-model="options.timeComponent" :hide-header="true"> </b-time>
                                </div>
                            </div>
                            <div class="form-group mb-3 bg-light p-2 radius">
                                <b-row>
                                    <b-col>
                                        <label class="text-primary">
                                            <font-awesome-icon class="text-primary" icon="user" />
                                            User Trip Preferences
                                        </label>
                                    </b-col>
                                </b-row>
                                <p class="text-black-50 small">Food</p>
                                <input id="food" v-model="options.food" :style="foodCss" class="form-control-range"
                                    max="100" min="0" name="food" type="range" @input="updateFood" />
                                <p class="text-black-50 small">Nature</p>
                                <input id="nature" v-model="options.nature" :style="natureCss"
                                    class="form-control-range" max="100" min="0" name="nature" type="range"
                                    @input="updateNature" />
                                <p class="text-black-50 small">Shop</p>
                                <input id="shop" v-model="options.shop" :style="shopCss" class="form-control-range"
                                    max="100" min="0" name="shop" type="range" @input="updateShop" />
                            </div>
                            <!-- lunch -->
                            <div class="form-group mb-3 bg-light p-2 radius">
                                <b-row>
                                    <b-col>
                                        <b-button :aria-expanded="wantsLunch ? 'true' : 'false'"
                                            :class="wantsLunch ? null : 'collapsed'" aria-controls="collapse-4" block
                                            size="sm" variant="outline-primary" style="text-align: left!important;"
                                            @click="wantsLunch = !wantsLunch">
                                            <b-checkbox disabled style="display: inline;" v-model="wantsLunch">
                                            </b-checkbox>
                                            <font-awesome-icon icon="utensils" />
                                            {{ wantsLunch ? "Cancel" : "I want to lunch" }}
                                        </b-button>
                                        <b-collapse v-model="wantsLunch" class="mt-2">
                                            <div class="form-group mb-3 bg-light p-2 radius">
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <strong>Earliest arrival:</strong>
                                                        </div>
                                                        <div class="text-center">
                                                            <b-time id="time" v-model="lunch.from" :hide-header="true">
                                                            </b-time>
                                                            <br>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <strong>Latest arrival:</strong>
                                                        </div>
                                                        <div class="text-center">
                                                            <b-time id="time" v-model="lunch.to" :hide-header="true">
                                                            </b-time>
                                                            <br>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <strong>Duration (Hours):</strong>
                                                            <input id="lunchDuration" v-model="lunch.duration"
                                                                :style="lunchDurationCss" class="form-control-range"
                                                                max="3" min="1" name="lunchDuration" type="range" />
                                                            <small class="bg-primary text-white text-center rounded p-1"
                                                                style="display: inline;">{{
                                                                        lunch.duration
                                                                }} h</small>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </div>
                            <!-- stay -->
                            <div class="form-group mb-3 bg-light p-2 radius">
                                <b-row>
                                    <b-col>
                                        <b-button :aria-expanded="wantsStay ? 'true' : 'false'"
                                            :class="wantsStay ? null : 'collapsed'" aria-controls="collapse-4" block
                                            size="sm" variant="outline-primary" style="text-align: left!important;"
                                            @click="wantsStay = !wantsStay">
                                            <b-checkbox disabled style="display: inline;" v-model="wantsStay">
                                            </b-checkbox>
                                            <font-awesome-icon icon="bed" />
                                            {{ wantsStay ? "Cancel" : "I want to stay for the night" }}
                                        </b-button>
                                        <b-collapse v-model="wantsStay" class="mt-2">
                                            <div class="form-group mb-3 bg-light p-2 radius">
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <small>Earliest Check-in</small>
                                                        </div>
                                                        <div class="text-center">
                                                            <b-time id="time" v-model="stay.startCheckIn"
                                                                :hide-header="true"> </b-time>
                                                            <br>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                 <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <small>Latest Check-in</small>
                                                        </div>
                                                        <div class="text-center">
                                                            <b-time id="time" v-model="stay.endCheckIn"
                                                                :hide-header="true"> </b-time>
                                                            <br>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <div class="text-center">
                                                            <br>
                                                            <small>Check-out:</small>
                                                        </div>
                                                        <div class="text-center">
                                                            <b-time id="time" v-model="stay.checkOut"
                                                                :hide-header="true"> </b-time>
                                                            <br>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="form-group mb-3 bg-light p-2 radius">
                                <b-row>
                                    <b-col>
                                        <label class="text-primary">
                                            <font-awesome-icon class="text-primary" icon="plug" />
                                            Plugs
                                        </label>
                                        <plug-selector-wrap :plugs="plugs"
                                            v-on:update-selected-plug="updateSelectedPlugs"></plug-selector-wrap>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-collapse>
                        <div class="bg-white p-4 fixed-bottom shadow">
                            <button class="btn btn-primary btn-block" v-bind:disabled="isDisabled"
                                v-on:click.prevent="submitForm">
                                <font-awesome-icon icon="route" />&nbsp;&nbsp;{{ $t("planner.form.planButtonText") }}
                            </button>
                        </div>
                    </form>
                </b-card-body>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import json from '@/data/ev.json';
import StackHeader from '@/components/StackHeader';
import PlugSelectorWrap from '@/components/PlugSelector';
// css
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
    components: {
        StackHeader,
        PlugSelectorWrap,
    },
    data() {
        return {
            map: null,
            geoLocate: null,
            evData: json,
            show: false,
            isTracking: false,
            lastKnowLocation: null,
            source: null,
            sourceMarker: null,
            sourceName: '',
            destination: null,
            destinationMarker: null,
            destinationName: '',
            form: {
                sourceLat: null,
                sourceLon: null,
                destinationLat: null,
                destinationLon: null,
                car: this.$store.state.carId,
                battery: this.$store.state.battery,
            },
            options: {
                endBattery: this.$store.state.endBattery,
                timeComponent: new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
                food: this.$store.state.food,
                nature: this.$store.state.nature,
                shop: this.$store.state.shop,
            },
            lunch: {
                from: 12 + ':' + 30 + ':' + '00',
                to: 13 + ':' + 30 + ':' + '00',
                duration: 1,
            },
            stay: {
                startCheckIn: 19 + ':' + 30 + ':' + '00',
                endCheckIn: 20 + ':' + 30 + ':' + '00',
                checkOut: 8 + ':' + 30 + ':' + '00',
            },
            advanceOptionsAreVisible: false,
            wantsLunch: false,
            wantsStay: false,
            waypoints: [],
            plugs: [],
            plugsSelected: [],
        };
    },
    computed: {
        isNotSelected: function () {
            return !this.form.car.length > 0
        },
        isDisabled: function () {
            return !(
                this.form.sourceLat != null &&
                this.form.sourceLon != null &&
                this.form.destinationLat != null &&
                this.form.destinationLon != null &&
                this.form.car.length > 0 &&
                this.form.battery != null
            );
        },
        startBatteryCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #45C2B1 0%, #45C2B1 ' +
                this.form.battery +
                '%, #eaeaea ' +
                this.form.battery +
                '%)'
            );
        },
        endBatteryCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #33B0FF 0%, #33B0FF ' +
                this.options.endBattery +
                '%, #eaeaea ' +
                this.options.endBattery +
                '%)'
            );
        },
        foodCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #FFD633 0%, #FFD633 ' +
                this.options.food +
                '%, #eaeaea ' +
                this.options.food +
                '%)'
            );
        },
        natureCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #177C49 0%, #177C49 ' +
                this.options.nature +
                '%, #eaeaea ' +
                this.options.nature +
                '%)'
            );
        },
        shopCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #BA1989 0%, #BA1989 ' +
                this.options.shop +
                '%, #eaeaea ' +
                this.options.shop +
                '%)'
            );
        },
        lunchDurationCss: function () {
            return (
                'background: -webkit-linear-gradient(left, #45C2B1 0%, #45C2B1 ' +
                ((this.lunch.duration - 1) * 50) +
                '%, #eaeaea ' +
                ((this.lunch.duration - 1) * 50) +
                '%)'
            );
        },
    },
    watch: {
        waypoints: {
            handler(val, oldVal) {
                const app = this;
                val.forEach((waypoint, index) => {
                    setTimeout(function () {
                        const container = '#wp_' + index;
                        const id = 'wp_' + index;
                        const element = document.getElementById(id);
                        console.log(element);
                        if (!element.hasChildNodes()) {
                            app.setGeocoder(container, index);
                        }
                    }, 200);
                });
            },
            deep: true,
        },
    },
    methods: {
        getPlugs: function () {
            this.plugs = []
            this.evData.data.forEach((ev, index) => {
                if (this.form.car === ev.id) {
                    ev.ac_charger.ports.forEach((port, index) => {
                        console.log(port)
                        this.plugs.push({
                            id: port,
                            description: port,
                            image: port + '.png',
                        });
                    });
                    ev.dc_charger.ports.forEach((port, index) => {
                        this.plugs.push({
                            id: port,
                            description: port,
                            image: port + '.png',
                        });
                    });
                }
            });
            console.log(this.plugs)
        },
        addWaypoint: function () {
            this.waypoints.push({
                name: '',
                lat: '',
                lon: '',
                object: null,
                options: [
                    { text: 'Pass', value: 't' },
                    { text: 'Charge', value: 'c' },
                    { text: 'Night', value: 'n' },
                    { text: 'Lunch', value: 'l' },
                ],
                selected: 'c',
            });
        },
        removeWaypoint: function (index) {
            this.waypoints.splice(index, 1);
            this.boundsMap();
        },
        setGeocoder: function (container, index) {
            const geocoder = new MapboxGeocoder({
                types: 'place, postcode, address',
                accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
                placeholder: 'Waypoint',
                mapboxgl: mapboxgl,
            });
            geocoder.addTo(container);
            // Add geocoder result to container.
            geocoder.on('result', (e) => {
                if (this.waypoints.length > 0) {
                    const waypoint = this.waypoints[index];
                    if (waypoint != null) {
                        waypoint.object = e.result;
                        waypoint.lat = waypoint.object.center[1];
                        waypoint.lon = waypoint.object.center[0];
                        this.boundsMap();
                    }
                }
            });
            geocoder.on('clear', () => { });
        },
        updateBattery(el) {
            this.$store.commit('setBattery', el.target.value);
        },
        updateEndBattery(el) {
            this.$store.commit('setEndBattery', el.target.value);
        },
        updateFood(el) {
            this.$store.commit('setFood', el.target.value);
        },
        updateNature(el) {
            this.$store.commit('setNature', el.target.value);
        },
        updateShop(el) {
            this.$store.commit('setShop', el.target.value);
        },
        updateCar(el) {
            this.$store.commit('setCarId', el.target.value);
            this.getPlugs()
        },
        updateSelectedPlugs(plugs) {
            this.plugsSelected = plugs
        },
        UpdateValues(e) {
            this.barMinValue = e.minValue;
            this.barMaxValue = e.maxValue;
        },
        invert() {
            const tempSource = this.source;
            const tempDest = this.destination;
            this.source = tempDest;
            this.destination = tempSource;
            //
            const startPoint = this.$t('planner.form.startPoint');
            const sourceEl = document.querySelector(`[placeholder=${CSS.escape(startPoint)}]`);

            const destination = this.$t('planner.form.destination');
            const destinationEl = document.querySelector(`[placeholder=${CSS.escape(destination)}]`);

            const tempSourceName = sourceEl.value;
            const tempDestName = destinationEl.value;
            sourceEl.value = tempDestName;
            destinationEl.value = tempSourceName;

            this.form.sourceLat = this.source.center[1];
            this.form.sourceLon = this.source.center[0];
            this.form.destinationLat = this.destination.center[1];
            this.form.destinationLon = this.destination.center[0];
            this.boundsMap();
        },
        boundsMap() {
            //clear map
            this.$mapHelpers.clearMap(this.map);
            //

            if (this.source != null && this.destination != null) {
                //one way points
                //source marker
                if (this.sourceMarker != null) {
                    this.sourceMarker.remove();
                    this.sourceMarker = null;
                }
                if (this.source.center !== this.lastKnowLocation) {
                    this.sourceMarker = new mapboxgl.Marker({
                        className: 'marker',
                        color: '#45C2B1',
                        anchor: 'bottom',
                    })
                        .setLngLat(this.source.center)
                        .addTo(this.map);
                }
                //destination marker
                if (this.destinationMarker != null) {
                    this.destinationMarker.remove();
                    this.destinationMarker = null;
                }
                if (this.destination.center !== this.lastKnowLocation) {
                    this.destinationMarker = new mapboxgl.Marker({
                        className: 'marker',
                        color: '#303434',
                        anchor: 'bottom',
                    })
                        .setLngLat(this.destination.center)
                        .addTo(this.map);
                }
                let waypointsCoords = [this.source.center];
                //waypoints markers

                if (this.waypoints.length > 0) {
                    this.waypoints.forEach((waypoint, index) => {
                        waypointsCoords.push(waypoint.object.center);
                    });
                }
                waypointsCoords.push(this.destination.center);
                //drawline
                this.map.addSource('rect', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: waypointsCoords,
                        },
                    },
                });
                this.map.addLayer({
                    id: 'rect',
                    type: 'line',
                    source: 'rect',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-color': '#303434',
                        'line-width': 3,
                        'line-opacity': 0.9,
                        'line-dasharray': [5, 2],
                    },
                });
                //animation
                let padding = { top: 80, bottom: 50, left: 400, right: 80 };
                if (window.outerWidth < 600) {
                    padding = { top: 40, bottom: 40, left: 20, right: 30 };
                }
                const bounds = new mapboxgl.LngLatBounds(this.source.center, this.destination.center);
                this.waypoints.forEach((waypoint) => {
                    bounds.extend(waypoint.center);
                });
                this.map.fitBounds(bounds, { padding: padding });
            }
        },
        localiseMe() {
            this.geoLocate.trigger();
        },
        getLocation(center) {
            axios
                .get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${center[0]},${center[1]}.json?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
                )
                .then((res) => {
                    //Perform Success Action
                    this.source = res.data.features[0];
                    this.$store.commit('setSource', this.source);
                    this.form.sourceLat = this.source.center[1];
                    this.form.sourceLon = this.source.center[0];
                    const startPoint = this.$t('planner.form.startPoint');
                    const sourceEl = document.querySelector(`[placeholder=${CSS.escape(startPoint)}]`);
                    sourceEl.value = this.source.place_name;
                    this.lastKnowLocation = this.source.center;
                    this.boundsMap();
                })
                .catch((error) => {
                    console.log(error);
                    // error.response.status Check status code
                })
                .finally(() => {
                    //Perform action in always
                });
        },
        pushRoutes(routes) {
            this.$store.commit('setRoutes', routes);
            //push route
            this.$router.push({ name: 'Routes' });
        },
        submitForm() {
            this.show = true;
            const config = {
                headers: {
                    'x-api-key': process.env.VUE_APP_API_PLANNER_KEY,
                },
            };
            let payload = this.form;
            if (this.advanceOptionsAreVisible) {
                const now = new Date();
                const str =
                    now.getFullYear().toString() +
                    '-' +
                    (now.getMonth() + 1).toString() +
                    '-' +
                    now.getUTCDate() +
                    ' ';
                const dateTimeString = str + this.options.timeComponent;
                let food = 0.0;
                if (this.options.food > 0) {
                    food = this.options.food / 100;
                }
                let nature = 0.0;
                if (this.options.nature > 0) {
                    nature = this.options.nature / 100;
                }
                let shop = 0.0;
                if (this.options.shop > 0) {
                    shop = this.options.shop / 100;
                }
                let selectedPlugs = ""
                if (this.plugsSelected.length > 0) {
                    selectedPlugs = this.plugsSelected.join(";")
                }
                const userMood = food.toFixed(2) + ';' + nature.toFixed(2) + ';' + shop.toFixed(2);
                payload = Object.assign(
                    this.form, { endBattery: this.options.endBattery }, { startDateTime: dateTimeString }, { userMood: userMood }, { selectedPlugs: selectedPlugs }
                );
                if (this.wantsLunch) {
                    const lunchFromTimeString = this.lunch.from
                    const lunchToTimeString = this.lunch.to
                    const lunchDuration = this.lunch.duration
                    payload = Object.assign(
                        this.form, { lunchFromTimeString: lunchFromTimeString }, { lunchToTimeString: lunchToTimeString }, { lunchDuration: lunchDuration }
                    );
                }
                if (this.wantsStay) {
                    const stayStartCheckIn = this.stay.startCheckIn
                    const stayEndCheckIn = this.stay.endCheckIn
                    const stayCheckOut = this.stay.checkOut
                    payload = Object.assign(
                        this.form, { stayStartCheckIn: stayStartCheckIn }, { stayEndCheckIn: stayEndCheckIn }, { stayCheckOut: stayCheckOut }
                    )
                }
            }
            if (this.waypoints.length > 0) {
                payload = Object.assign(payload, { waypoints: this.waypoints });
            }
            axios
                .post(process.env.VUE_APP_API_PLANNER_URL, payload, config)
                .then((res) => {
                    //Perform Success Action
                    if (res.data != null) {
                        this.$store.commit('setPayload', payload);
                        if (res.data.length > 0) {
                            let code = res.data[0].code;
                            let message = res.data[0].message;
                            console.log(message)
                            if (message != null) {
                                //error 1 - 2
                                console.log("inside")
                                this.$store.commit('setBattery', 100)
                                this.$store.commit('setEndBattery', 10)
                                this.form.battery = 100
                                this.form.endBattery = 10
                                //!
                                this.$bvModal.msgBoxOk(message)
                                    .then(value => {
                                        if (res.data[0].routes.length > 0) {

                                            this.pushRoutes(res.data);
                                        }
                                    })
                                    .catch(err => {
                                        // An error occurred
                                    })
                                console.log(message);
                            } else {
                                if (res.data[0].routes.length > 0) {
                                    this.pushRoutes(res.data);
                                }
                            }
                        } else {
                            //error 0
                            this.$bvModal.msgBoxOk('No route was found using the current input parameters. Displaying route with a different Battery at Start levels');
                            this.$store.commit('setBattery', 100)
                            this.$store.commit('setEndBattery', 10)
                            this.form.battery = 100
                            this.form.endBattery = 10
                            console.log('error 0');
                        }
                    }
                    this.show = false;
                })
                .catch((error) => {
                    this.show = false;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        this.$bvModal.msgBoxOk(error.response.data.error);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
                .finally(() => {
                    //Perform action in always
                    this.show = false;
                });
        },
    },
    mounted() {
        ///////////////// MAP ////////////////
        if (this.map == null) {
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: process.env.VUE_APP_MAPBOX_MAP_STYLE,
                center: [5.5, 47], // starting position [lng, lat]
                zoom: 4.2, // starting zoom
                attributionControl: false,
            });
            this.$store.commit('setMap', this.map);
        }
        //events
        this.map.on('load', () => { });
        this.map.on('moveend', () => { });
        this.geoLocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: false,
            },
            trackUserLocation: true,
            showUserHeading: false,
        });
        //add navigation controls
        this.map.addControl(new mapboxgl.NavigationControl());
        // Add geoLocate control to the map.
        this.map.addControl(this.geoLocate);
        ////
        //clear map
        this.$mapHelpers.clearMap(this.map);
        //
        ////////////////////////////////////
        const geocoderStart = new MapboxGeocoder({
            trackProximity: true,
            types: 'place, postcode,address',
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
            placeholder: this.$t('planner.form.startPoint'),
            mapboxgl: mapboxgl,
        });
        geocoderStart.addTo('#source');

        // Add geocoder result to container.
        geocoderStart.on('result', (e) => {
            this.source = e.result;
            this.form.sourceLat = this.source.center[1];
            this.form.sourceLon = this.source.center[0];
            this.$store.commit('setSource', this.source);
            this.boundsMap();
        });
        // Clear results container when search is cleared.
        geocoderStart.on('clear', () => { });
        //end
        const geocoderEnd = new MapboxGeocoder({
            trackProximity: true,
            types: 'place, postcode, address',
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
            placeholder: this.$t('planner.form.destination'),
            mapboxgl: mapboxgl,
        });
        geocoderEnd.addTo('#destination');

        // Add geocoder result to container.
        geocoderEnd.on('result', (e) => {
            this.destination = e.result;
            this.form.destinationLat = this.destination.center[1];
            this.form.destinationLon = this.destination.center[0];
            this.$store.commit('setDestination', this.destination);
            this.boundsMap();
        });
        // Clear results container when search is cleared.
        geocoderEnd.on('clear', () => { });
        //start
        this.geoLocate.on('trackuserlocationstart', () => {
            console.log('A trackuserlocationstart event has occurred.');
            this.isTracking = true;
        });
        this.geoLocate.on('trackuserlocationend', () => {
            console.log('A trackuserlocationend event has occurred.');
            this.isTracking = false;
        });
        //
        this.geoLocate.on('geolocate', (e) => {
            const lon = e.coords.longitude;
            const lat = e.coords.latitude;
            const center = [lon, lat];
            this.getLocation(center);
        });
        this.getPlugs()
    },
};
</script>
<style lang="scss">
.row {
    margin-right: -15px !important;
    margin-left: -15px !important;
}

.input-group-text {
    width: 40px;
}

.input-group-text span {
    margin: 0 auto;
}

#source,
#destination {
    z-index: 3;
    padding: 0 !important;
}

#destination {
    z-index: 1;
    padding: 0 !important;
}

.waypoint {
    z-index: 2;
    padding: 0 !important;
}

#source_result,
#destination_result {
    z-index: 2;
}

.mapboxgl-ctrl-geocoder--input {
    height: 36px;
}

.mapboxgl-ctrl-geocoder--icon-search {
    top: 7px;
}

.mapboxgl-ctrl-geocoder {
    min-width: 100%;
}

.form-group {
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.bottom-padding {
    padding-bottom: 110px !important;
}

.dotted::after {
    content: '  --------';
    letter-spacing: 2px;
    color: #45c2b1;
}

.dotted::before {
    content: '--------  ';
    letter-spacing: 2px;
    color: #45c2b1;
}
</style>