export const mapHelpers = {
    timeDurationString: function (timeString) {
        const sec_num = parseInt(timeString, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return hours + 'h ' + minutes + 'm '
    },
    formatDistance: function (
        distance, precision = 2
    ) {
        const km = distance / 1000;
        return km.toFixed(precision) + " km"
    },
    getTime: function (dateTimeString) {
        const date = new Date(dateTimeString)
        // Hours part from the timestamp
        const hours = date.getHours()
        // Minutes part from the timestamp
        const minutes = "0" + date.getMinutes()
        // Seconds part from the timestamp
        const formattedTime = hours + ':' + minutes.substr(-2)
        return formattedTime
    },
    subStringTime: function (dateTimeString) {
        return dateTimeString.substring(dateTimeString.length - 5)
    },
    clearMap(map) {
        //remove alternatives
        if (map.getLayer('alternatives')) {
            map.removeLayer('alternatives')
        }
        if (map.getSource('alternatives')) {
            map.removeSource('alternatives')
        }
        //near_chargers
        if (map.getLayer('near_chargers')) {
            map.removeLayer('near_chargers')
        }
        if (map.getSource('near_chargers')) {
            map.removeSource('near_chargers')
        }
        //remove chargers
        if (map.getLayer('chargers')) {
            map.removeLayer('chargers')
        }
        if (map.getSource('chargers')) {
            map.removeSource('chargers')
        }
        //remove line
        if (map.getLayer('rect')) {
            map.removeLayer('rect')
        }
        if (map.getSource('rect')) {
            map.removeSource('rect')
        }
        //remove routes
        for (let index = 0; index < 3; index++) {
            const idRoute = 'route_' + index
            if (map.getLayer(idRoute)) {
                map.removeLayer(idRoute)
            }
            if (map.getSource(idRoute)) {
                map.removeSource(idRoute)
            }
        }
        //popups
        const popups = document.getElementsByClassName('mapboxgl-popup')
        if (popups != null) {
            if (popups.length) {
                for (let i = 0; i < popups.length; i++) {
                    popups[i].remove();
                }
            }
        }
    }
}