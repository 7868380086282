import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        map: null,
        routes: null,
        selectedRoute: null,
        source: null,
        destination: null,
        carId: '',
        battery: 90,
        endBattery: 15,
        food: 0,
        nature: 0,
        shop: 0,
        panelIsVisible: true,
        panelButtonsAreVisible: false,
        payload: null

    },
    mutations: {
        initialiseStore(state) {
            if (localStorage.getItem('battery')) {
                // Replace the state object with the stored item
                state.battery = localStorage.getItem('battery')
            }
            if (localStorage.getItem('endBattery')) {
                // Replace the state object with the stored item
                state.endBattery = localStorage.getItem('endBattery')
            }
            if (localStorage.getItem('carId')) {
                // Replace the state object with the stored item
                state.carId = localStorage.getItem('carId')
            }
            if (localStorage.getItem('food')) {
                // Replace the state object with the stored item
                state.food = localStorage.getItem('food')
            }
            if (localStorage.getItem('nature')) {
                // Replace the state object with the stored item
                state.nature = localStorage.getItem('nature')
            }
            if (localStorage.getItem('shop')) {
                // Replace the state object with the stored item
                state.shop = localStorage.getItem('shop')
            }
        },
        setMap(state, map) {
            state.map = map
        },
        setRoutes(state, routes) {
            state.routes = routes
        },
        setSelectedRoute(state, route) {
            state.selectedRoute = route
        },
        setSource(state, source) {
            state.source = source
        },
        setDestination(state, dest) {
            state.destination = dest
        },
        setCarId(state, carId) {
            state.carId = carId
        },
        setBattery(state, battery) {
            state.battery = battery
        },
        setEndBattery(state, endBattery) {
            state.endBattery = endBattery
        },
        setFood(state, food) {
            state.food = food
        },
        setNature(state, nature) {
            state.nature = nature
        },
        setShop(state, shop) {
            state.shop = shop
        },
        setPanelIsVisible(state, panelIsVisible) {
            state.panelIsVisible = panelIsVisible
        },
        setPanelButtonsAreVisible(state, panelButtonsAreVisible) {
            state.panelButtonsAreVisible = panelButtonsAreVisible
        },
        setPayload(state, payload) {
            state.payload = payload
        },
    },
    getters: {
        panelIsVisible: (state) => {
            return state.panelIsVisible
        },
        panelButtonsAreVisible: (state) => {
            return state.panelButtonsAreVisible
        }
    },
    actions: {}
})
