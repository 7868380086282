<template>
    <div id="app">
        <!--toggle button -->
        <b-button v-show="panelButtonsAreVisible" v-b-toggle.sidebar class="p-2 btn-toggle btn-light text-center">
            <font-awesome-icon icon="bars"></font-awesome-icon>
        </b-button>
        <!--main nav panel -->
        <b-sidebar id="sidebar" :no-close-on-route-change="true" :visible="panelIsVisible" aria-labelledby="sidebar-no-header-title" class="p-1" no-header shadow>
            <template #default="{ hide }">
                <div class="row p-1 tit-side">
                <div class="float-l  p-3 text-center" style="margin-left: 10px;">
                  <img alt="logo" src="@/assets/images/eCharge_logo.svg" width="100px" />
                </div>
                <div class="float-l  p-3 text-center">
                  <img alt="logo" src="@/assets/images/European-union-flag.png" width="30px" />
                </div>
                <div class="float-l  p-2 smallest" style="width:130px;margin-top: 4px;">
                  <p class="smallest">co-funded by the EU under the H2020 Research and Innovation Programme (grant agreement No 875131)</p>
                </div>
                <div class="float-r text-right p-1" style="margin-top: 10px;margin-left: 10px;">
                <button v-show="panelButtonsAreVisible" aria-label="Close" class="close text-dark right m-1" type="button" @click="hide">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                    </button>
                </div>
              </div>
               
                  
                    
                <transition>
                    <router-view />
                </transition>
            </template>
        </b-sidebar>
        <!-- map view on bkg -->
        <div id="map"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "App",
    metaInfo: {
        title: "eCharge4Drivers + evway - Planner",
    },
    computed: {
        ...mapGetters(["panelIsVisible", "panelButtonsAreVisible"]),
    },
    data() {
        return {
            transitionName: "forward",
        };
    },
    methods: {
        onResizeEvent(e) {
            this.setPanelBools(e.target);
        },
        setPanelBools(el) {
            if (this.$route.query.opened != "true"){
                this.$store.commit("setPanelIsVisible", el.outerWidth > 600);
                this.$store.commit(
                    "setPanelButtonsAreVisible",
                    el.outerWidth < 600
                );
            } 
        },
    },
    created() {
        this.setPanelBools(window);
        window.addEventListener("resize", this.onResizeEvent);
    },
};
</script>
<style lang="scss">
.tit-side {
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.smallest {
    font-size: 8px !important;
}
.btn-toggle {
    top: 10px;
    left: 10px;
    position: fixed;
    z-index: 999;
    width: 48px;
    border: 1px solid #a3a3a3;
}

.sidebar {
    position: relative;
}
.b-sidebar{
    width: 380px;
}
</style>