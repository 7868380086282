<template>
    <div>
        <table class="table" cellpadding="0" cellspacing="0">
            <tr>
                <td v-for="(row, index) in occupation" :key="index">
                    <small>&nbsp;{{ getRow() }}%&nbsp;</small>
                </td>
            </tr>
            <tr>
                <td v-for="(row, index) in occupation" :key="index" class="data">
                    <div class="bar" :style="{'height': `${parseInt(getRow())}%`}"></div>
                </td>
            </tr>
            <tr>
                <td v-for="(row, index) in occupation" :key="index">
                    <small>{{ row.hour }}</small>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'OccupationChart',
    data() {
        return {
            occupation: []
        }
    },
    props: {
        occupation: {
            type: Array,
            default: [],
            required: true
        }
    },
    components: {},
    computed: {},
    watch: {},
    methods: {
        getRow: function() {
            let min = Math.ceil(8);
            let max = Math.floor(87);
            return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
        }
    }
}
</script>
<style lang="scss">
.table td {
    width:21px;
    padding: 0;
}
.data {
    height: 100px;
    position: relative;
}

.bar {
    bottom: 0;
    width: 20px;
    display: block;
    position: absolute;
    background-color: #45C2B1;
}
</style>