<template>
    <div class="plug-selector-wrap">
        <template v-for="plug in plugs">
            <div :key="plug.id" class="plug-wrap" @click="selectPlug(plug)" v-bind:class="{'selected': plug.selected}">
                <div class="container">
                    <div><img :src="getImgUrl(plug.image)"></div>
                    <!--<img src="@/assets/images/plugs/Schuko.png">-->
                    <p v-html="plug.description"></p>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'plug-selector-wrap',
    props: {
        plugs: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            selectedPlugs: [],
        };
    },
    watch: {
        plugs() {
            this.initPlugList();
        },
    },
    methods: {
        getImgUrl(pic) {
            return require('../assets/images/plugs/' + pic)
        },
        selectPlug(plug) {
            const indexPlug = this.selectedPlugs.indexOf(plug.id);
            if (plug.selected == true) {
                plug.selected = false;
                if (indexPlug >= 0) {
                    this.selectedPlugs.splice(indexPlug, 1)
                }
            } else {
                plug.selected = true;
                this.selectedPlugs.push(plug.id);
            }
            this.$emit('update-selected-plug', this.selectedPlugs);
        },
        initPlugList() {
            for (let i = 0; i < this.plugs.length; i++) {
                this.$set(this.plugs[i], 'selected', true);
                this.selectedPlugs.push(this.plugs[i])
            }
        },
    },
    created() {
        this.initPlugList();
    },
};
</script>
<style lang="scss">
.plug-selector-wrap {
    display: flex;
    width: 100%;

    .plug-wrap {
        width: 33.33333333%;
        text-align: center;
        padding: 7px;
        cursor: pointer;

        &.selected {
            .container {
                border: 2px solid #45C2B1;

                div {
                    background: #45C2B1;
                }
            }
        }

        .container {
            padding: 7px;
            border-radius: 10px;
            border: 2px solid gray;

            div {
                margin: auto;
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background: gray;
                padding: 8px;
            }

            img {
                width: 100%;
                margin-top: -1px;
                max-width: 40px;
            }

            p {
                margin: 0;
                text-transform: capitalize;
                font-size: 0.8rem;
                padding: 7px 0px;
                text-align: center;
            }
        }
    }
}
</style>