<template>
    <div>
        <b-overlay :show="show" rounded="sm">
            <div :aria-hidden="show ? 'true' : null">
                <stack-header :show-back-arrow="true" :title="getTitle(route)" />
                <b-card-body>
                    <timeline>
                        <timeline-item bg-color="#45C2B1">
                            <p><strong>Start:</strong> {{ this.source.place_name }}</p>
                        </timeline-item>
                        <template v-for="(step, index) in timeline" :data-index="index">
                            <timeline-item v-if="isCharger(index) === true" bg-color="#88B04B">
                                <div>
                                    Stop at:
                                    <br />
                                    <div style="width: 280px; overflow: hidden">
                                        <a :data-index="index" class="btn btn-primary btn-sm" @click="showCharger">{{
                                        step.external_id }}</a>
                                    </div>
                                    <br />
                                    <br />
                                    <strong>Type: {{ step.type }} </strong>
                                    <font-awesome-icon :icon="iconName(step.type)" class="text-primary">
                                    </font-awesome-icon>
                                    <br />
                                    <br />
                                    Address:
                                    <br />
                                    <strong>{{ step.address }}</strong>
                                    <br />
                                    <br />
                                    Connector:
                                    <strong>{{ step.port }} | {{ step.pow }} kW</strong>
                                    <br />
                                    Arrival time: <strong>{{ subStringTime(step.reach_time) }}</strong>
                                    <br />
                                    Start SoC: <strong>{{ step.ini_soc }} %</strong>
                                    <br />
                                    Charge Time: <strong>{{ getDuration(step.time) }}</strong>
                                    <br />
                                    End SoC: <strong>{{ step.fin_soc }} %</strong>
                                    <br />
                                    <br />
                                    <a :data-index="index" class="btn btn-secondary btn-sm"
                                        @click="showAlternatives">Show Alternatives</a>
                                    <br />
                                    <br />
                                    <strong><span class="text-secondary">Occupation Data:</span></strong>
                                    <br />
                                    <div class="list-group-item" style="width: 280px; overflow: scroll">
                                        <occupation-chart :occupation="step.occupancy"></occupation-chart>
                                    </div>
                                    <br />
                                </div>
                            </timeline-item>
                            <timeline-item v-else :data-index="index" :hollow="true">
                                <p>
                                    Travel for: <strong>{{ getDuration(step.duration) }}</strong>
                                    <br />
                                    Via: <strong>{{ step.summary }}</strong>
                                </p>
                            </timeline-item>
                        </template>
                        <timeline-item bg-color="#0F4C81">
                            <p><strong>Arrival:</strong> {{ this.destination.place_name }}</p>
                        </timeline-item>
                    </timeline>
                    <div id="route" class="list-group bottom-margin">
                        <div class="list-group-item m-3">
                            <p class="list-group-item-text">
                                Total Duration:
                                <strong>{{ getDuration(route.routes[0].duration) }}</strong>
                                <br />
                                Total Distance:
                                <strong>{{ getDistance(route.routes[0].distance) }}</strong>
                                <br />
                                Number of Stops: <strong>{{ route.chargers.length }}</strong>
                                <br />
                                SoC at Arrival: <strong>{{ route.end_soc }}%</strong>
                            </p>
                        </div>
                    </div>
                </b-card-body>
                <div class="bg-white p-4 fixed-bottom shadow" v-if="!visible">
                    <a :href="openInEvway" class="btn btn-primary btn-block">
                        <font-awesome-icon icon="map-pin" />&nbsp;&nbsp;Open in evway®
                    </a>
                    <a class="btn btn-block btn-outline-primary" @click="copy">
                        <font-awesome-icon icon="share-square" />&nbsp;&nbsp;Copy evway® Link 
                    </a>
                    <a class="btn btn-block btn-outline-primary" target="_blank" @click="downloadItem">
                        <font-awesome-icon icon="cloud-download-alt" />&nbsp;&nbsp;Download GeoJson
                    </a>
                </div>
                <div class="bg-white p-4 fixed-bottom shadow" v-if="visible">
                    <a class="btn btn-primary btn-block" @click="openFromWebView">
                        <font-awesome-icon icon="map-pin" />&nbsp;&nbsp;Open in evway®
                    </a>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import StackHeader from '@/components/StackHeader';
import OccupationChart from '@/components/OccupationChart';
import MarkerPopUp from '@/components/MarkerPopUp';
import axios from 'axios';
// css
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
//timeline
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline';
import 'vue-cute-timeline/dist/index.css';

export default {
    data() {
        return {
            show: false,
            timeline: [],
            source: this.$store.state.source,
            destination: this.$store.state.destination,
            routes: this.$store.state.routes,
            route: this.$store.state.selectedRoute,
            index: this.$route.params.index,
            map: this.$store.state.map,
            color: this.$route.params.color,
            prevLegIndex: -1,
            chargerIndex: -1,
            payload: this.$store.state.payload,
            visible: false
        };
    },
    computed: {
        shareWA: function () {
            var response = structuredClone(this.route)
            delete response.all_chargers
            response.chargers.forEach(function(charger) {
                delete charger.occupancy
                charger.alternatives.forEach(function(alternative) {
                    delete alternative.occupancy
                })
            })
            //encode
            var polyline = require('@mapbox/polyline')
            response.routes[0].geometry.coordinates.forEach(function(i) {
                    i.reverse()
            })
            const encoded = polyline.encode(response.routes[0].geometry.coordinates, 5)
            response = Object.assign(response, {"duration" : response.routes[0].duration})
            response = Object.assign(response, {"distance" : response.routes[0].distance})
            response = Object.assign(response, {"carId" : this.$store.state.carId})
            response = Object.assign(response, {"endBattery" : this.$store.state.endBattery})
            delete response.routes
            response = Object.assign(response, {"encoded" : encoded})
            console.log(response)
            return 'whatsapp://send?text=' + encodeURIComponent(JSON.stringify(response));
        },
        openInEvway: function () {
            var response = structuredClone(this.route)
            delete response.all_chargers
            response.chargers.forEach(function(charger) {
                delete charger.occupancy
                charger.alternatives.forEach(function(alternative) {
                    delete alternative.occupancy
                })
            })
            //encode
            var polyline = require('@mapbox/polyline')
            response.routes[0].geometry.coordinates.forEach(function(i) {
                    i.reverse()
            })
            const encoded = polyline.encode(response.routes[0].geometry.coordinates, 5)
            response = Object.assign(response, {"duration" : response.routes[0].duration})
            response = Object.assign(response, {"distance" : response.routes[0].distance})
            response = Object.assign(response, {"carId" : this.$store.state.carId})
            response = Object.assign(response, {"finalBattery" : this.$store.state.endBattery})
            delete response.routes
            response = Object.assign(response, {"encoded" : encoded})
            console.log(response)
            return 'r220evway://route/' + encodeURIComponent(JSON.stringify(response));
        },
        openInGoogleMaps: function () {
            const url = 'https://www.google.com/maps/dir/?api=1';
            const origin = this.route.waypoints[0].location[1] + ',' + this.route.waypoints[0].location[0];
            const destination =
                this.route.waypoints[this.route.waypoints.length - 1].location[1] +
                ',' +
                this.route.waypoints[this.route.waypoints.length - 1].location[0];
            let waypoints = '';
            this.route.waypoints.forEach((waypoint) => {
                waypoints = waypoints + waypoint.location[1] + ',' + waypoint.location[0] + '|';
            });
            return (
                url +
                '&origin=' +
                encodeURIComponent(origin) +
                '&destination=' +
                encodeURIComponent(destination) +
                '&waypoints=' +
                encodeURIComponent(waypoints)
            );
        }
    },
    components: {
        MarkerPopUp,
        OccupationChart,
        StackHeader,
        Timeline,
        TimelineItem,
        TimelineTitle,
    },
    methods: {
        openFromWebView() {
            var response = structuredClone(this.route)
            delete response.all_chargers
            response.chargers.forEach(function(charger) {
                delete charger.occupancy
                charger.alternatives.forEach(function(alternative) {
                    delete alternative.occupancy
                })
            })
            //encode
            var polyline = require('@mapbox/polyline')
            response.routes[0].geometry.coordinates.forEach(function(i) {
                    i.reverse()
            })
            const encoded = polyline.encode(response.routes[0].geometry.coordinates, 5)
            response = Object.assign(response, {"duration" : response.routes[0].duration})
            response = Object.assign(response, {"distance" : response.routes[0].distance})
            response = Object.assign(response, {"carId" : this.$store.state.carId})
            response = Object.assign(response, {"endBattery" : this.$store.state.endBattery})
            delete response.routes
            response = Object.assign(response, {"encoded" : encoded})
            console.log(response)
            if (typeof webkit !== 'undefined'){
                webkit.messageHandlers.route.postMessage(JSON.stringify(response));
            }
        },
        downloadItem() {
            var response = structuredClone(this.route)
            delete response.all_chargers
            response.chargers.forEach(function(charger) {
                delete charger.occupancy
                charger.alternatives.forEach(function(alternative) {
                    delete alternative.occupancy
                })
            })
            //encode
            var polyline = require('@mapbox/polyline')
            response.routes[0].geometry.coordinates.forEach(function(i) {
                    i.reverse()
            })
            console.log(response.routes[0].geometry.coordinates)
            const encoded = polyline.encode(response.routes[0].geometry.coordinates, 5)
            response = Object.assign(response, {"duration" : response.routes[0].duration})
            response = Object.assign(response, {"distance" : response.routes[0].distance})
            response = Object.assign(response, {"carId" : this.$store.state.carId})
            response = Object.assign(response, {"endBattery" : this.$store.state.endBattery})
            delete response.routes
            response = Object.assign(response, {"encoded" : encoded})
            console.log(response)
            let filename = "ec4d_planner_route.json"
            const blob = new Blob([JSON.stringify(response)], { type: 'text/json' });
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                const elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        },
        copy() {
            var response = structuredClone(this.route)
            delete response.all_chargers
            response.chargers.forEach(function(charger) {
                delete charger.occupancy
                charger.alternatives.forEach(function(alternative) {
                    delete alternative.occupancy
                })
            })
            //encode
            var polyline = require('@mapbox/polyline')
            response.routes[0].geometry.coordinates.forEach(function(i) {
                    i.reverse()
            })
            console.log(response.routes[0].geometry.coordinates)
            const encoded = polyline.encode(response.routes[0].geometry.coordinates, 5)
            response = Object.assign(response, {"duration" : response.routes[0].duration})
            response = Object.assign(response, {"distance" : response.routes[0].distance})
            response = Object.assign(response, {"carId" : this.$store.state.carId})
            response = Object.assign(response, {"endBattery" : this.$store.state.endBattery})
            options: {
                endBattery: this.$store.state.endBattery,
                delete response.routes
                response = Object.assign(response, {"encoded" : encoded})
                console.log(response)
                this.$bvModal.msgBoxOk('Copied!');
                navigator.clipboard.writeText('r220evway://route/' + encodeURIComponent(JSON.stringify(response)));
            }
        },
        iconName(name) {
            switch (name) {
                case 'Charger':
                    return 'bolt';
                case 'Lunch':
                case 'Dinner':
                    return 'utensils';
                case 'Night':
                    return 'bed';
                default:
                    return 'map-signs';
            }
        },
        recalculate(e, chargeId, alternativeId) {
            this.show = true;
            const routeId = this.route.id;
            const payload = {
                route: routeId,
                waypoints: [],
            };
            this.route.waypoints.forEach((waypoint) => {
                if (waypoint.id === chargeId) {
                    payload.waypoints.push(alternativeId);
                } else {
                    payload.waypoints.push(waypoint.id);
                }
            });
            // TODO check if waypoints is charger and set charger id
            const config = {
                headers: {
                    'x-api-key': process.env.VUE_APP_API_PLANNER_KEY,
                },
            };
            axios
                .post(process.env.VUE_APP_API_RECALCULATE_URL, payload, config)
                .then((res) => {
                    //Perform Success Action
                    if (res.data != null) {
                        let code = res.data.code;
                        let message = res.data.message;
                        if (code.includes('Error')) {
                            //error 1 - 2
                            this.$bvModal.msgBoxOk(message);
                            console.log(code + '|' + message);
                        } else {
                            //reset data
                            this.timeline = [];
                            this.prevLegIndex = -1;
                            this.chargerIndex = -1;

                            this.route = res.data;
                            this.route = res.data;
                            this.setInitialPrevIndex();
                            this.populateMap();
                        }
                    } else {
                        //error 0
                        this.$bvModal.msgBoxOk('Error Try Again');
                        console.log('error 0');
                    }

                    this.show = false;
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.show = false;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        this.$bvModal.msgBoxOk(error.response.data.error);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
                .finally(() => {
                    //Perform action in always
                    this.show = false;
                });
        },

        reRoute(coordinates, chargeId) {
            this.show = true;
            console.log(coordinates);
            const coords = coordinates.split(',');
            const config = {
                headers: {
                    'x-api-key': process.env.VUE_APP_API_PLANNER_KEY,
                },
            };
            const waypoint = {
                name: 'recalulate',
                lat: coords[1],
                lon: coords[0],
                object: {},
                options: [
                    { text: 'Pass', value: 't' },
                    { text: 'Charge', value: 'c' },
                    { text: 'Night', value: 'n' },
                    { text: 'Lunch', value: 'l' },
                ],
                selected: 'c',
            };

            let payload = this.payload;
            if (payload.hasOwnProperty('waypoints')) {
                //new replace near point
                let from = turf.point([waypoint['lon'], waypoint['lat']])
                let options = { units: 'kilometers' }
                var distance = 100000
                var raplaceIndex = 0
                //
                payload.waypoints.forEach((point, index) => {
                    console.log(point['lat'] + " " + point['lon'])
                    let to = turf.point([point['lon'], point['lat']])
                    let currentDistance = turf.distance(from, to, options)
                    if (currentDistance < distance) {
                        distance = currentDistance
                        raplaceIndex = index
                        console.log("new near distance: " + distance + " | raplace index:" + raplaceIndex)
                    }
                })
                payload.waypoints[raplaceIndex] = waypoint;
            } else {
                let waypoints = [];
                waypoints.push(waypoint);

                payload = Object.assign(payload, { waypoints: waypoints });
            }
            axios
                .post(process.env.VUE_APP_API_PLANNER_URL, payload, config)
                .then((res) => {
                    //Perform Success Action
                    if (res.data != null) {
                        this.$store.commit('setPayload', payload);
                        if (res.data.length > 0) {
                            let code = res.data[0].code;
                            let message = res.data[0].message;
                            if (code.includes('Error')) {
                                //error 1 - 2
                                this.$bvModal.msgBoxOk(message);
                                console.log(code + '|' + message);
                            } else {
                                this.pushRoutes(res.data);
                            }
                        } else {
                            //error 0
                            this.$bvModal.msgBoxOk('Error Try Again');
                            console.log('error 0');
                        }
                    }
                    this.show = false;
                })
                .catch((error) => {
                    this.show = false;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        this.$bvModal.msgBoxOk(error.response.data.error);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
                .finally(() => {
                    //Perform action in always
                    this.show = false;
                });
        },
        pushRoutes(routes) {
            this.$store.commit('setRoutes', routes);
            //push route
            this.$router.push({ name: 'Routes' });
        },
        setInitialPrevIndex() {
            for (let i = 0; i < this.route.routes[0].legs.length - 1; i++) {
                const leg = this.route.routes[0].legs[i];
                if (leg != null) {
                    if (leg.charge_port !== 'null') {
                        this.prevLegIndex = i - 1;
                        break;
                    }
                }
            }
        },
        showCharger(e) {
            const index = e.target.dataset.index;
            const charger = this.timeline[index];
            var bounds = new mapboxgl.LngLatBounds(charger.location, charger.location);
            bounds.extend(charger.location);
            let padding = { top: 160, bottom: 160, left: 420, right: 160 };
            if (window.outerWidth < 600) {
                padding = { top: 40, bottom: 40, left: 20, right: 30 };
            }
            this.map.fitBounds(bounds, { padding: padding });
        },
        showAlternatives(e) {
            const index = e.target.dataset.index;
            const charger = this.timeline[index];
            const alternatives = charger.alternatives;
            var bounds = new mapboxgl.LngLatBounds(charger.location, charger.location);
            alternatives.forEach((alternative) => {
                bounds.extend(alternative.location);
            });
            let padding = { top: 80, bottom: 80, left: 400, right: 80 };
            if (window.outerWidth < 600) {
                padding = { top: 40, bottom: 40, left: 20, right: 30 };
            }
            this.map.fitBounds(bounds, { padding: padding });
        },
        isCharger(index) {
            const leg = this.route.routes[0].legs[index];
            if (leg != null) {
                if (leg.charge_port === 'null') {
                    return false;
                }
                return true;
            }
            return false;
        },

        getCharger(index) {
            if (index > this.prevLegIndex) {
                this.prevLegIndex = index;
                this.chargerIndex = this.chargerIndex + 1;
            }

            if (this.chargerIndex < this.route.chargers.length) {
                let charger = this.route.chargers[this.chargerIndex];
                this.route.waypoints.forEach((waypoint) => {
                    const chargeType = { charge_type: charger.type };
                    if (waypoint.id === charger.id) {
                        charger = Object.assign(charger, waypoint, chargeType);
                    }
                });
                return charger;
            }
            return null;
        },
        getDuration(distance) {
            if (distance == null) return '';
            return this.$mapHelpers.timeDurationString(distance);
        },
        getDistance(distance) {
            if (distance == null) return '';
            return this.$mapHelpers.formatDistance(distance, 2);
        },
        subStringTime(dateTime) {
            if (dateTime == null) return '';
            return this.$mapHelpers.subStringTime(dateTime);
        },
        getTitle(route) {
            let names = route.name.split(":")
            let routeTitle = names[0].slice(0, -2)
            let addressTitle = ": " + names[1]
            return routeTitle + addressTitle
        },
        addRoute(route) {
            //add routes
            const geometry = route.routes[0].geometry;
            this.map.addSource('route_' + this.index, {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: geometry,
                },
            });
            const color = this.color;
            this.map.addLayer({
                id: 'route_' + this.index,
                type: 'line',
                source: 'route_' + this.index,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': color,
                    'line-opacity': 1,
                    'line-width': 7,
                },
            });
            const coordinates = geometry.coordinates;
            // Create a 'LngLatBounds' with both corners at the first coordinate.

            var bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of coordinates) {
                bounds.extend(coord);
            }
            let padding = { top: 80, bottom: 50, left: 400, right: 80 };
            if (window.outerWidth < 600) {
                padding = { top: 40, bottom: 40, left: 20, right: 30 };
            }
            this.map.fitBounds(bounds, { padding: padding });
        },
        addStations(route) {
            const geojson = {
                type: 'FeatureCollection',
                features: [],
            };
            route.chargers.forEach((charger, index) => {
                var marker = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: charger.location,
                    },
                    properties: {
                        title: 'c_' + index,
                        description: charger.name,
                    },
                };
                geojson.features.push(marker);
            });
            this.map.addSource('chargers', {
                type: 'geojson',
                data: geojson,
            });
            this.map.addLayer({
                id: 'chargers',
                type: 'circle',
                source: 'chargers',
                paint: {
                    'circle-radius': 10,
                    'circle-color': '#88B04B',
                    'circle-stroke-color': '#FFFFFF',
                    'circle-stroke-width': 2,
                },
                filter: ['==', '$type', 'Point'],
            });
            this.map.moveLayer('chargers');
        },
        addAlternatives(route) {
            const geojson = {
                type: 'FeatureCollection',
                features: [],
            };
            route.chargers.forEach((charger, index) => {
                charger.alternatives.forEach((alternative, index) => {
                    var marker = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: alternative.location,
                        },
                        properties: {
                            title: 'a_' + index,
                            external_id: alternative.external_id,
                            address: alternative.address,
                            power: alternative.pow,
                            plug: alternative.port,
                            description: alternative.name,
                            chargerId: charger.id,
                            alternativeId: alternative.id,
                        },
                    };
                    geojson.features.push(marker);
                });
            });
            this.map.addSource('alternatives', {
                type: 'geojson',
                data: geojson,
            });
            this.map.addLayer({
                id: 'alternatives',
                type: 'circle',
                source: 'alternatives',
                paint: {
                    'circle-radius': 8,
                    'circle-color': '#45C2B1',
                    'circle-stroke-color': '#FFFFFF',
                    'circle-stroke-width': 3,
                },
                filter: ['==', '$type', 'Point'],
            });
        },
        addChargersNearRoute(route) {
            const geojson = {
                type: 'FeatureCollection',
                features: [],
            };
            route.all_chargers.forEach((alternative, index) => {
                var marker = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: alternative.location,
                    },
                    properties: {
                        title: 'n_' + index,
                        external_id: alternative.external_id,
                        address: alternative.address,
                        power: alternative.pow,
                        plug: alternative.port,
                        description: alternative.name,
                        chargerId: alternative.id,
                        alternativeId: alternative.id,
                    },
                };
                geojson.features.push(marker);
            });
            this.map.addSource('near_chargers', {
                type: 'geojson',
                data: geojson,
            });
            this.map.addLayer({
                id: 'near_chargers',
                type: 'circle',
                source: 'near_chargers',
                paint: {
                    'circle-radius': 6,
                    'circle-opacity': 0.5,
                    'circle-color': '#333333',
                    'circle-stroke-color': '#FFFFFF',
                    'circle-stroke-width': 1,
                },
                filter: ['==', '$type', 'Point'],
            });
        },
        setUpTimeLine() {
            this.timeline = [];
            if (this.route.routes[0].legs.length > 0) {
                const legs = this.route.routes[0].legs;
                for (let index = 0; index < legs.length - 1; index++) {
                    if (this.isCharger(index) === true) {
                        this.timeline.push(this.getCharger(index));
                    } else {
                        this.timeline.push(legs[index]);
                    }
                }
            }
        },
        populateMap() {
            this.$mapHelpers.clearMap(this.map);
            this.addChargersNearRoute(this.route);
            this.addRoute(this.route);
            this.addAlternatives(this.route);
            this.addStations(this.route);
            this.setUpTimeLine();
        },
    },
    created() {
        this.setInitialPrevIndex();
        if (window.navigator.userAgent.includes("evway")) {
            this.visible = true
        }
    },
    mounted() {
        ///////////////// MAP ////////////////
        if (this.map == null) {
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: process.env.VUE_APP_MAPBOX_MAP_STYLE,
                center: [12.49, 41.9], // starting position [lng, lat]
                zoom: 12, // starting zoom
                attributionControl: false,
            });
            this.$store.commit('setMap', this.map);
            //events
            this.map.on('load', () => {
                console.log('loaded');
                //this.getMarkers()
            });
            this.map.on('moveend', () => {
                console.log('A moveend event occurred.');
                //this.getMarkers()
            });
            this.geoLocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: false,
                },
                trackUserLocation: true,
                showUserHeading: false,
            });
            //add navigation controls
            this.map.addControl(new mapboxgl.NavigationControl());
            // Add geoLocate control to the map.
            this.map.addControl(this.geoLocate);
        }
        ////////////////////////////////////
        this.populateMap();
        //events alternatives
        this.map.on('click', 'alternatives', (e) => {
            const coordinates = e.features[0].geometry.coordinates;
            const chargeId = e.features[0].properties.chargerId;
            const alternativeId = e.features[0].properties.alternativeId;
            const name = e.features[0].properties.description;
            const plug = e.features[0].properties.plug;
            const power = e.features[0].properties.plug;
            const extenal_id = e.features[0].properties.external_id;
            const address = e.features[0].properties.address;
            this.map.flyTo({
                center: coordinates.slice(),
            });
            const html =
                `
                <p class="m-3 p-2">
                    ` +
                extenal_id +
                `
                    Plug ` +
                plug +
                ` | ` +
                power +
                ` kW
                    <small>` +
                address +
                `</small>
                    <a  id="btn-alternative" class="btn btn-sm btn-primary btn-block" data-charger="` +
                chargeId +
                `" data-alternative="` +
                alternativeId +
                `">
                        Charge on Station
                    </a>
                </p>
            `;
            new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(this.map);
            return false;
        });
        //events alternatives
        this.map.on('click', 'near_chargers', (e) => {
            const coordinates = e.features[0].geometry.coordinates;
            const chargeId = e.features[0].properties.chargerId;
            const name = e.features[0].properties.description;
            const plug = e.features[0].properties.plug;
            const power = e.features[0].properties.plug;
            const extenal_id = e.features[0].properties.external_id;
            const address = e.features[0].properties.address;
            this.map.flyTo({
                center: coordinates.slice(),
            });
            const html =
                `
                <p class="m-3 p-2">
                    ` +
                extenal_id +
                `
                    Plug ` +
                plug +
                ` | ` +
                power +
                ` kW
                    <small>` +
                address +
                `</small>
                    <a  id="btn-near-charger" class="btn btn-sm btn-primary btn-block" data-coordinates="` +
                coordinates +
                `" data-chargeId="` +
                chargeId +
                `">
                        Use This Station For Charging*
                    </a>
                    <small>* This will replace the nearest charger</small
                </p>
            `;
            new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(this.map);
            return false;
        });
        this.map.on('mouseenter', 'alternatives', () => {
            this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'alternatives', () => {
            this.map.getCanvas().style.cursor = '';
        });
        const vm = this;
        document.addEventListener('click', function (e) {
            if (e.target.getAttribute('id') === 'btn-alternative') {
                console.log(e.target.dataset.charger);
                console.log(e.target.dataset.alternative);
                vm.recalculate(e, e.target.dataset.charger, e.target.dataset.alternative);
            }
            if (e.target.getAttribute('id') === 'btn-near-charger') {
                console.log(e.target.dataset.coordinates);
                console.log(e.target.dataset.chargeId);
                vm.reRoute(e.target.dataset.coordinates, e.target.dataset.chargeId);
            }
        });
    },
};
</script>
<style lang="scss">
.list-group-item-action {
    cursor: pointer;
}

.list-group-item-action * {
    pointer-events: none;
}

.bottom-margin {
    margin-bottom: 130px;
}
.card-body {
    margin-bottom: 90px;
}
.position-absolute {
    z-index: 1000 !important;
    position: fixed !important;
}
</style>