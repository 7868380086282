<template>
    <div>
        <stack-header :on-back-pressed="reset" :show-back-arrow="true" :title="$t('routes.title')" />
        <div id="routes" class="list-group">
            <a v-for="(route, index) in routes" :data-index="index" class="list-group-item list-group-item-action" @click="clickOnRoute" @mouseout="resetRoutes" @mouseover="glowRoute">
                <p class="list-group-item-heading left">{{ $t("routes.via") }}: <strong>{{
                        getTitle(route, index)
                        }}</strong></p>
                <p class="list-group-item-text left">
                    Duration: <strong>{{ getDuration(route.routes[0].duration) }}</strong>
                    <br>
                    Distance: <strong>{{ getDistance(route.routes[0].distance) }}</strong>
                    <br>
                    Number of Stops: <strong>{{ route.chargers.length }}</strong>
                    <br>
                    Soc at Arrival: <strong>{{ route.end_soc }}%</strong>
                </p>
                <span class="fa-pull-right">
                    <font-awesome-icon icon="chevron-right" /></span>
            </a>
        </div>
    </div>
</template>
<script>
import mapboxgl from 'mapbox-gl'
import StackHeader from "@/components/StackHeader";
// css
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
    data() {
        return {
            routes: this.$store.state.routes,
            map: this.$store.state.map,
        }
    },
    components: {
        StackHeader
    },
    methods: {
        getDuration(distance) {
            return this.$mapHelpers.timeDurationString(distance)
        },
        getDistance(distance) {
            return this.$mapHelpers.formatDistance(distance, 2)
        },
        getTitle(route, index) {
            let number = index + 1
            let names = route.name.split(":")
            let routeTitle = names[0].slice(0, -2) + " " + number
            let addressTitle = ": " + names[1]
            return routeTitle + addressTitle
        },
        getColor(index) {
            let color = '#45C2B1';
            if (index == 1) {
                color = '#88B04B'
            }
            if (index == 2) {
                color = '#0F4C81'
            }
            return color
        },
        addRoutes(routes) {
            //clear map
            this.$mapHelpers.clearMap(this.map)
            //add routes
            var bounds = null;
            routes.forEach((route, index) => {
                const geometry = route.routes[0].geometry
                this.map.addSource('route_' + index, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': geometry
                    }
                })
                let color = this.getColor(index)
                this.map.addLayer({
                    'id': 'route_' + index,
                    'type': 'line',
                    'source': 'route_' + index,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': color,
                        'line-opacity': 1,
                        'line-width': 7
                    }
                });

                const coordinates = geometry.coordinates;
                // Create a 'LngLatBounds' with both corners at the first coordinate.
                if (bounds == null) {
                    bounds = new mapboxgl.LngLatBounds(
                        coordinates[0],
                        coordinates[0]
                    )
                } else {
                    bounds.extend([
                        coordinates[0],
                        coordinates[0]
                    ]);
                }
                // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
                for (const coord of coordinates) {
                    bounds.extend(coord);
                }

            })
            let padding = { top: 80, bottom: 50, left: 400, right: 80 };
            if (window.outerWidth < 600) {
                padding = { top: 40, bottom: 40, left: 20, right: 30 }
            }
            this.map.fitBounds(bounds, { padding: padding })

        },
        glowRoute(e) {
            const index = e.target.dataset.index
            const idRoute = 'route_' + index
            this.map.moveLayer(idRoute)
            this.routes.forEach((element, index) => {
                const allRoute = 'route_' + index
                this.map.setPaintProperty(allRoute, 'line-width', 7);
            })
            this.map.setPaintProperty(idRoute, 'line-width', 12);
        },
        resetRoutes() {
            this.routes.forEach((element, index) => {
                const allRoute = 'route_' + index
                this.map.setPaintProperty(allRoute, 'line-width', 7);
            })
        },
        clickOnRoute(e) {
            const index = e.target.dataset.index
            const route = this.routes[index]
            this.$store.commit('setSelectedRoute', route)
            this.$router.push({ name: 'SelectedRoute', params: { index: index, color: this.getColor(index) } });
        },
        reset() {},
    },
    mounted() {
        ///////////////// MAP ////////////////
        if (this.map == null) {
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
            this.map = new mapboxgl.Map({
                container: 'map', // container ID
                style: process.env.VUE_APP_MAPBOX_MAP_STYLE,
                center: [12.49, 41.90], // starting position [lng, lat]
                zoom: 12, // starting zoom
                attributionControl: false,
            });
            this.$store.commit('setMap', this.map)
            //events
            this.map.on('load', () => {
                console.log("loaded")
                //this.getMarkers()
            });
            this.map.on('moveend', () => {
                console.log('A moveend event occurred.')
                //this.getMarkers()
            });
            this.geoLocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: false
                },
                trackUserLocation: true,
                showUserHeading: false
            })
            //add navigation controls
            this.map.addControl(new mapboxgl.NavigationControl())
            // Add geoLocate control to the map.
            this.map.addControl(this.geoLocate);
        }
        ////////////////////////////////////
        this.addRoutes(this.routes)
    }
}
</script>
<style lang="scss">
.list-group-item-action {
    cursor: pointer;
}

.list-group-item-action * {
    pointer-events: none;
}
</style>