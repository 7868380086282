<template>
    <header class="stack-header">
        <div v-if="showBackArrow" class="h-left">
            <slot name="h-left">
                <font-awesome-icon icon="arrow-left" @click="onBack" />
            </slot>
        </div>
        <h1>{{ title }}</h1>
    </header>
</template>
<script>
export default {
    name: 'StackHeader',
    data() {
        return {};
    },
    props: {
        title: {
            type: String,
            default: '',
            required: false
        },
        showBackArrow: {
            type: Boolean,
            default: false,
            required: false
        },
        onBackPressed: {
            type: Function,
            default: () => true
        }
    },
    components: {},
    computed: {},
    watch: {
        title: {
            handler: function(title) {
                window.document.title = title
            },
            immediate: true
        }
    },
    methods: {
        onBack() {
            this.onBackPressed()
            this.$router.back()
        },
        onForward() {
            this.$router.forward()
        }
    }
};
</script>
<style lang="scss">
.stack-header {

    line-height: 44px;
    width: 100%;
    text-align: center;
    position: relative;
    background-color: #fff;
    border-bottom: #e5e5e5 1px solid;

    >h1 {
        font-size: 18px;
        color: #5b5a67;
        padding-top: 10px;
        padding-left: 35px;
        padding-right: 35px;
    }

    >.h-left {
        position: absolute;
        top: 0;
        left: 0;

        font-size: 16px;
        padding: 0 15px;
        color: #5b5a67;
        cursor: pointer;
    }

    >.h-right {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;

        i {
            font-size: 16px;
            color: #a3a3a3;
        }

        font-size: 16px;
        color: #ccc;
        padding-right: 10px;
        display: flex;
    }
}
</style>