import Vue from 'vue'
import VueRouter from 'vue-router'
import Plan from '../views/Plan.vue'
import Routes from '../views/Routes.vue'
import Route from '../views/Route.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Plan',
        component: Plan
    },
    {
        path: '/routes',
        name: 'Routes',
        component: Routes
    },
    {
        path: '/selected-route',
        name: 'SelectedRoute',
        component: Route
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
