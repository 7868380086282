//import scss
import '@/assets/styles/app.scss'
//import css
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
//import core plugins
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//i18n
import i18n from './i18n'
//axios
import axios from "axios"
import VueAxios from "vue-axios"
//bootstrap
import {
    ModalPlugin,
    AspectPlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    FormGroupPlugin,
    FormPlugin,
    FormRadioPlugin,
    InputGroupPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    OverlayPlugin,
    SidebarPlugin,
    TimePlugin,
    FormCheckboxPlugin
} from "bootstrap-vue";
//fontawesome
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faArrowLeft,
    faBars,
    faBatteryThreeQuarters,
    faBed,
    faBolt,
    faCarAlt,
    faChevronRight,
    faClock,
    faCrosshairs,
    faFlag,
    faMapPin,
    faMapSigns,
    faRandom,
    faRoute,
    faShareSquare,
    faTimes,
    faUser,
    faUtensils,
    faPlug,
    faMapMarker,
    faCloudDownloadAlt,
} from '@fortawesome/free-solid-svg-icons'
//vue meta
import VueMeta from 'vue-meta'
//helpers
import {mapHelpers} from './helpers/mapHelpers'

Vue.use(VueAxios, axios)
Vue.use(LayoutPlugin)
Vue.use(AspectPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(InputGroupPlugin)
Vue.use(CardPlugin)
Vue.use(OverlayPlugin)
Vue.use(ListGroupPlugin)
Vue.use(SidebarPlugin)
Vue.use(ButtonPlugin)
Vue.use(CollapsePlugin)
Vue.use(TimePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(ModalPlugin)
library.add(faCrosshairs)
library.add(faRandom)
library.add(faMapPin)
library.add(faFlag)
library.add(faCarAlt)
library.add(faBatteryThreeQuarters)
library.add(faRoute)
library.add(faArrowLeft)
library.add(faChevronRight)
library.add(faBars)
library.add(faTimes)
library.add(faShareSquare)
library.add(faClock)
library.add(faUtensils)
library.add(faBed)
library.add(faBolt)
library.add(faMapSigns)
library.add(faUser)
library.add(faPlug)
library.add(faMapMarker)
library.add(faCloudDownloadAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueMeta)
Vue.prototype.$mapHelpers = mapHelpers

//config
Vue.config.productionTip = false
//start...
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    beforeCreate() {
        this.$store.commit('initialiseStore');
    }
}).$mount('#app')
//save
store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('battery', state.battery);
    localStorage.setItem('endBattery', state.endBattery);
    localStorage.setItem('carId', state.carId);
    localStorage.setItem('food', state.food);
    localStorage.setItem('nature', state.nature);
    localStorage.setItem('shop', state.shop);
});
